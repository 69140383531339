import { Carrier } from './enums';
import { CartBuilderInterface } from './builders/cartBuilderInterface';
import { AutoCompletedAddress, Cart, Catalog, CatalogProductOffering, Outages, ServiceQualification } from './clients/types';

export class PaymentError extends Error { }

/**
 * A way to interact with the integrations of Uniti's systems.
 */
export interface IntegrationFacadeInterface {
  /**
   * From a partial address, retrieve a list of valid addresses.
   *
   * @param partialAddress
   */
  getAddresses(partialAddress: string): Promise<Array<AutoCompletedAddress>>;

  /**
   * Run service qualification based on either an address ID, or an
   * address/carrier/carrier_id combination.
   *
   * @param id
   * @param address
   * @param carrier
   * @param carrierId
   */
  doServiceQualification(id: string | null, address: string | null, carrier: Carrier | null, carrierId: string | null): Promise<Array<ServiceQualification>>;

  /**
   * For a service, get a list of valid plans from the catalog.
   *
   * @param serviceQualification
   */
  getPlansForServiceQualification(serviceQualification: ServiceQualification): Promise<Array<CatalogProductOffering>>;

  /**
   * For a service, get a list of valid hardware from the catalog.
   *
   * @param serviceQualification
   */
  getHardwareForServiceQualification(serviceQualification: ServiceQualification): Promise<Array<CatalogProductOffering>>;

  /**
   * For a service, get a list of valid voice products from the catalog.
   *
   * @param serviceQualification
   */
  getVoiceForServiceQualification(serviceQualification: ServiceQualification): Promise<Array<CatalogProductOffering>>;

  /**
   * For a service, get a list of valid fees from the catalog.
   *
   * @param serviceQualification
   */
  getFeesForServiceQualification(serviceQualification: ServiceQualification): Promise<Array<CatalogProductOffering>>;

  /**
   * For a service, get a list of valid promotion products from the catalog.
   *
   * @param serviceQualification
   */
  getPromotionsForServiceQualification (serviceQualification: ServiceQualification): Promise<Array<CatalogProductOffering>>;

  /**
   * Return an instance of a cart builder.
   *
   * @param id The id of the cart, if this is going to
   *  update an existing cart.
   */
  createCartBuilder(id?: string): CartBuilderInterface;

  /**
   * Save a cart to the DB.
   *
   * @param cart
   */
  saveCart(cart: Cart): Promise<Cart>;

  /**
   * Saves the customer's payment details in bpoint and the shopping cart.
   *
   * @throws PaymentError
   *
   * @param cartId
   * @param cardName
   * @param cardNumber
   * @param cardExpiry
   * @param cartCvc
   */
  savePaymentToCart(cartId: string, cardName: string, cardNumber: number, cardExpiry: string, cartCvc: string): Promise<string>;

  /**
   * return the full catalogs object from the catalogs API.
   */
  getCatalogs (): Promise<Array<Catalog>>;

  fetchOutages (): Promise<Outages>;
}
