import { inject, injectable } from 'inversify';
import { HttpClientInterface } from '../http/httpClientInterface';
import { OutageClientInterface } from './outageClientInterface';
import { Outages } from './types';

/**
 * @inheritdoc
 */
@injectable()
export class OutageClient implements OutageClientInterface {
  constructor (@inject('OutageHttpClient') private httpClient: HttpClientInterface) { }

  /**
   * @inheritdoc
   */
  public async fetchOutages (): Promise<Outages> {
    const url = 'outage-list';

    const response = await this.httpClient.get<Outages>(url);
    console.log('ff', response);
    return response;
  }
}
