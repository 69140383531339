
import { Outages } from '@/services/integration/clients/types';
import { IntegrationFacadeInterface } from '../services/integration/integrationFacadeInterface';
import { inject, injectable } from 'inversify';

@injectable()
export class OutageAdapter {
  constructor (
    @inject('IntegrationFacadeInterface') private integrationFacade: IntegrationFacadeInterface
  ) { }

  public async fetchOutages (): Promise<Outages> {
    const outages = await this.integrationFacade.fetchOutages();

    return outages;
  }
}
