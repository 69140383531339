import { inject, injectable } from 'inversify';
import { BrandServiceInterface } from '../brand/brandServiceInterface';
import { Brand, Carrier } from './enums';
import { CartBuilderInterface } from './builders/cartBuilderInterface';
import { CatalogClientInterface } from './clients/catalogClientInterface';
import { ShoppingCartClientInterface } from './clients/shoppingCartClientInterface';
import { SqClientInterface } from './clients/sqClientInterface';
import { AutoCompletedAddress, Cart, Catalog, CatalogProductOffering, Outages, ServiceQualification } from './clients/types';
import { IntegrationFacadeInterface, PaymentError } from './integrationFacadeInterface';
import { CreditCardInformation, TokenProviderInterface } from '@/services/integration/clients/tokenProviderInterface';
import { OutageClientInterface } from './clients/outageClientInterface';

declare const CBA: {
  ProcessAddToken(options: Record<string, unknown>): void
};

@injectable()
export class IntegrationFacade implements IntegrationFacadeInterface {
  private static catalogs: Array<Catalog> = [];

  private static serviceQualifications: Array<ServiceQualification> = [];

  private static outages: Outages = {
    currentOutages: null,
    scheduledOutages: null
  };

  constructor (
    @inject('SqClientInterface') private sqClient: SqClientInterface,
    @inject('CatalogClientInterface') private catalogClient: CatalogClientInterface,
    @inject('ShoppingCartClientInterface') private shoppingCartClient: ShoppingCartClientInterface,
    @inject('OutageClientInterface') private outageClient: OutageClientInterface,
    @inject('CartBuilderInterface') private cartBuilder: CartBuilderInterface,
    @inject('BrandServiceInterface') private brandService: BrandServiceInterface,
    @inject('TokenProviderInterface') private tokenProvider: TokenProviderInterface) { }

  public async getAddresses (partialAddress: string): Promise<Array<AutoCompletedAddress>> {
    return await this.sqClient.autoCompleteAddress(partialAddress);
  }

  public async doServiceQualification (id: string | null, address: string | null, carrier: Carrier | null, carrierId: string | null): Promise<Array<ServiceQualification>> {
    // If we've already done this sq, return that. It's way quicker that way.
    const cachedServiceQualification = IntegrationFacade.serviceQualifications.find(serviceQualification => {
      if (id !== null) {
        return serviceQualification.id === id;
      }

      return false;
    });
    if (cachedServiceQualification) {
      return [cachedServiceQualification];
    }

    // Do the SQ by address id, as it is quicker. Otherwise we search on
    // the address and carrier if it is provided.
    if (id !== null) {
      const serviceQualifications = await this.sqClient.getService(id);
      IntegrationFacade.serviceQualifications = IntegrationFacade.serviceQualifications.concat(serviceQualifications);
      return serviceQualifications;
    } else if (address !== null) {
      const serviceQualifications = await this.sqClient.searchAddress(address, null, carrier, carrierId);
      IntegrationFacade.serviceQualifications = IntegrationFacade.serviceQualifications.concat(serviceQualifications);
      return serviceQualifications;
    } else {
      throw new Error('Error in doServiceQualification(): id or address must be supplied.');
    }
  }

  public async getPlansForServiceQualification (serviceQualification: ServiceQualification): Promise<Array<CatalogProductOffering>> {
    return this.filterCatalogs(serviceQualification, 'internet');
  }

  public async getHardwareForServiceQualification (serviceQualification: ServiceQualification): Promise<Array<CatalogProductOffering>> {
    return this.filterCatalogs(serviceQualification, 'hardware');
  }

  public async getVoiceForServiceQualification (serviceQualification: ServiceQualification): Promise<Array<CatalogProductOffering>> {
    return this.filterCatalogs(serviceQualification, 'voice');
  }

  public async getFeesForServiceQualification (serviceQualification: ServiceQualification): Promise<Array<CatalogProductOffering>> {
    return this.filterFeeCatalogs(serviceQualification, 'fee');
  }

  public async getPromotionsForServiceQualification (serviceQualification: ServiceQualification): Promise<Array<CatalogProductOffering>> {
    return this.filterCatalogs(serviceQualification, 'promotion');
  }

  public createCartBuilder (id?: string): CartBuilderInterface {
    // Sets the brand of the cart automatically here as well.
    return this.cartBuilder.create().createCart(id).setBrand(this.brandService.getName());
  }

  public async saveCart (cart: Cart): Promise<Cart> {
    if (cart.id !== null && cart.id) {
      return await this.shoppingCartClient.updateCart(cart);
    } else {
      return await this.shoppingCartClient.createCart(cart);
    }
  }

  public async getCatalogs (): Promise<Array<Catalog>> {
    // The catalog doesn't change that much, so we store it to save time.
    if (IntegrationFacade.catalogs.length) {
      return IntegrationFacade.catalogs;
    } else {
      const catalog = await this.catalogClient.getCatalogs(this.brandService.getName());
      IntegrationFacade.catalogs = catalog;
      return catalog;
    }
  }

  public async savePaymentToCart (cartId: string, cardName: string, cardNumber: number, cardExpiry: string, cartCvc: string): Promise<string> {
    const creditCardInfo: CreditCardInformation = {
      cardName: cardName,
      ccExpiryMonth: cardExpiry.split('/')[0],
      ccExpiryYear: cardExpiry.split('/')[1],
      creditCardNumber: cardNumber
    };

    try {
      return await this.tokenProvider.getCreditCardToken(cartId, creditCardInfo);
    } catch (e) {
      throw new PaymentError(e);
    }
  }

  public async fetchOutages (): Promise<Outages> {
    const outages = await this.outageClient.fetchOutages();
    console.log('cd', outages);
    IntegrationFacade.outages = outages;
    return outages;
  }

  private async filterCatalogs (serviceQualification: ServiceQualification, type: string): Promise<Array<CatalogProductOffering>> {
    const catalogs = await this.getCatalogs();
    const carrier = serviceQualification.carrier;
    const maxDownload = serviceQualification.max_download;

    // Has correct type.
    const types = ['internet', 'hardware', 'voice', 'fee', 'promotion'];
    if (!types.includes(type)) {
      throw new Error(`Incorrect type while filtering catalog products: ${type}`);
    }

    // Filter catalogs.
    const internetCatalogs = catalogs.filter(catalog => {
      return catalog.category.name === type;
    });

    // For all the sellable products in all the internet catalogs.
    let products: CatalogProductOffering[] = [];
    internetCatalogs.forEach(catalog => {
      const catalogCarrier = catalog.name;

      // Filter out products based on their type.
      const productsForCatalog = catalog.category.productOffering.filter(product => {
        let isValid = false;
        if (type === 'internet') {
          // Products must be sellable, of the same carrier of as the
          // SQ, and be under the max download limit.
          const download = product.prodSpecCharValueUse.find(o => o.name === 'Download')?.productSpecCharacteristicValue[0].value as number;
          isValid = product.isSellable && catalogCarrier === carrier && download <= maxDownload;
        } else if (type === 'hardware' || type === 'promotion') {
          // Make sure hardware is for the website brand.
          const brand = this.mapBrandNameToBrand(product.productSpecification.brand);
          const onBrand = brand === this.brandService.getName();
          isValid = product.isSellable && onBrand;
        } else {
          // Just in case.
          isValid = product.isSellable;
        }

        return isValid;
      });

      // Add products to all products.
      products = products.concat(productsForCatalog);
    });

    return products;
  }

  private async filterFeeCatalogs (serviceQualification: ServiceQualification, type: string): Promise<Array<CatalogProductOffering>> {
    const catalogs = await this.getCatalogs();
    const carrier = serviceQualification.carrier;

    // Has correct type.
    const types = ['fee'];
    if (!types.includes(type)) {
      throw new Error(`Incorrect type while filtering catalog products: ${type}`);
    }

    // Filter catalogs.
    const feeCatalogs = catalogs.filter(catalog => {
      return catalog.category.name === type;
    });

    const brandFeeCatalogs = feeCatalogs.filter(catalog => {
      const brandProducts = catalog.category.productOffering.filter(product => {
        const brand = this.mapBrandNameToBrand(product.productSpecification.brand);
        return product.isSellable && brand === this.brandService.getName();
      });
      if (brandProducts.length > 0) {
        return true;
      }
      return false;
    });

    // We filter by carrier to see if there are more specific fee products
    let filteredBrandFeeCatalogs = brandFeeCatalogs.filter(catalog => catalog.name === carrier);

    // For all the sellable products in the filtered fee catalog.
    let products: CatalogProductOffering[] = [];

    if (filteredBrandFeeCatalogs.length > 0) {
      filteredBrandFeeCatalogs.forEach(catalog => {
        const productsForCatalog = catalog.category.productOffering.filter(product => product.isSellable);
        // Add products to all products.
        products = products.concat(productsForCatalog);
      });
    } else {
      // If there is no carrier specific fee catalog we add all non-specific fee products
      filteredBrandFeeCatalogs = brandFeeCatalogs.filter(catalog => catalog.name === null);
      filteredBrandFeeCatalogs.forEach(catalog => {
        const productsForCatalog = catalog.category.productOffering.filter(product => product.isSellable);
        // Add products to all products.
        products = products.concat(productsForCatalog);
      });
    }

    return products;
  }

  private mapBrandNameToBrand (brand: string): Brand {
    const brands: { [ key: string ]: Brand } = {
      Uniti: Brand.UNI,
      'Harbour ISP': Brand.HIS,
      FuzeNet: Brand.FUZ,
      'Jamba powered by Uniti': Brand.JAM,
      Arklife: Brand.ARK,
      Bloom: Brand.BLM
    };

    return brands[brand];
  }
}
